import React, { Fragment, useEffect } from 'react';
import "./css/styles.css"
import { Routes, Route, Navigate } from 'react-router-dom';
import BookieList from './Bookie-List';

const App = () => {

    window.baseURL = document.getElementById("url").innerHTML;

    useEffect( () => {
        if ('serviceWorker' in navigator && 'PushManager' in window) {
            askPermission()
        } else {
            console.error('Push messaging is not supported')
        }

        const handleVisibilityChange = () => {
            if (document.hidden) {
                console.log("Page is in background")
                sendMessageToServiceWorker({ appInBackground: true })
            } else {
                console.log("Page is in foreground");
                sendMessageToServiceWorker({ appInBackground: false })
            }
        }

        document.addEventListener("visibilitychange", handleVisibilityChange);
        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        }
    }, [])


    const sendMessageToServiceWorker = (data) => {
        if (navigator.serviceWorker.controller) 
            navigator.serviceWorker.controller.postMessage({ type : "APP_VISIBILITY", payload : data })
    }
    

    const askPermission = () => {
        Notification.requestPermission().then(permission => {
            if (permission === 'granted') {
                console.log('Notification permission granted.');
                // Proceed to subscribe the user to push notifications
            } else {
                console.error('User denied notification permission.');
                // Handle the case where the user didn't grant permission
            }
        });
    }
    
    /*
    const subscribeToPushNotificationService = () => {
        serviceWorkerRegistration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: convertedVapidKey // your VAPID public key converted to a Uint8Array. VAPID (Voluntary Application Server Identification)
        })
        .then(subscription => {
            console.log('User is subscribed:', subscription);
            // !!! Send the subscription object to your server !!!
        })
        .catch(err => {
            console.error('Failed to subscribe the user:', err);
        });
    }
    */

    return (
        <Fragment>
            <Routes>
                <Route path="/" element={<Navigate replace to="/bookie-list" />}/>
                <Route path="/bookie-list" element={<BookieList />} />
                <Route path="*" element={<Navigate replace to="/" />} />
            </Routes>
        </Fragment>
    );
}

export default App;
