import { Fragment } from "react"
import { Container } from "react-bootstrap"
import BookieLogo from "./Bookie-Logo"

const BookieList = () => {

    return (<Fragment>
        <Container className="p-2">
            <h1 className="text-center">React JS PWA App</h1>
            <ul>
                <li><BookieLogo css_classname="club_logo" img_filename="1.png"/></li>
                <li><BookieLogo css_classname="club_logo" img_filename="2.png"/></li>
                <li><BookieLogo css_classname="club_logo" img_filename="3.png"/></li>
            </ul>
        </Container>
    </Fragment>)

}

export default BookieList