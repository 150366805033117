import { Fragment } from "react"
import { Image } from "react-bootstrap"
import "./css/styles.css"

const BookieLogo = ( props ) => {

    return (<Fragment>
        <Image className={props.css_classname} src={ window.baseURL + "images/" + props.img_filename } />
    </Fragment>)

}

export default BookieLogo